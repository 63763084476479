<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <template v-if="userData">
      <div>

        <!-- Media -->
        <b-media class="mb-0">
          <h4 class="mb-1">
            {{ userData.fullName }}
          </h4>
        </b-media>
        <validation-observer
          ref="refFormUserUpdateObserver"
        >
          <b-form @submit.prevent="updateUsers(userData)">
            <b-row class="mb-1 mt-0">
              <!-- Field: Full Name -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="firstname"
                  rules="required"
                >
                  <b-form-group
                    label="Voornaam"
                    label-for="firstname"
                  >
                    <b-form-input
                      id="firstName"
                      v-model="userData.firstName"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: MiddleName -->
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Tussenvoegsel"
                  label-for="middlename"
                >
                  <b-form-input
                    id="middlename"
                    v-model="userData.middleName"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Lastname -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="lastname"
                  rules="required"
                >
                  <b-form-group
                    label="Achternaam"
                    label-for="lastname"
                  >
                    <b-form-input
                      id="lastname"
                      v-model="userData.lastName"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Email -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="userData.email"
                      type="email"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Created date -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Aanmaakdatum"
                  label-for="createTimestamp"
                >
                  <b-form-input
                    id="createTimestamp"
                    v-model="userData.createTimestamp"
                    type="text"
                    readonly="readonly"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              <b-spinner
                small
                class="mr-1"
                v-if="showUpdateUserSpinner"
              />
              Opslaan
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </component>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BLink,
  BMedia,
  BOverlay,
  BRow,
  BSpinner,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import useUsersList from '@/views/pages/user/useUsersList'
import { formatDateTime, generatePassword } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BSpinner,
    BCardText,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showUploadLoader: false,
      showUpdateUserSpinner: false,
    }
  },
  mounted() {
    store.dispatch('app-users-edit/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        // eslint-disable-next-line no-param-reassign
        response.data.createTimestamp = formatDateTime(response.data.createTimestamp)
        this.userData = response.data
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ophalen gebruiker',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
          },
        })
      })
  },
  methods: {
    confirmPasswordResetText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, reset het wachtwoord!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.passwordReset(id)
          }
        })
    },
    passwordReset(userId) {
      const password = generatePassword()
      const passwordResetData = {
        id: userId,
        newPassword: password,
      }

      store.dispatch('apps-users-edit/patchUser', passwordResetData)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Wachtwoord Reset!',
            text: `De wachtwoord reset is met succes uitgevoerd. De nieuwe wachtwoord voor de gebruiker is: ${password}`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Wachtwoord Reset!',
            text: 'Er is een fout opgetreden bij het resetten van de password. Probeer later het nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    async updateUsers(userData) {
      this.showUpdateUserSpinner = true
      await this.$refs.refFormUserUpdateObserver.validate()
        .then(valid => {
          if (valid) {
            store.dispatch('app-users-edit/updateUser', userData)
              .then(() => {
                this.showUpdateUserSpinner = false
                router.push({ name: 'app-users-list' })
              })
              .catch(() => {
                this.showUpdateUserSpinner = false
                this.$swal({
                  icon: 'error',
                  title: 'Update gebruiker!',
                  text: 'Er is een fout opgetreden bij het updaten van de data. Probeer later het nog een keer.',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })
          }
        })
        .catch(() => {
          this.showUpdateUserSpinner = false
        })
    },
  },
  setup() {
    const userData = ref(null)
    const refFormUserUpdateObserver = ref(null)
    const USER_APP_STORE_MODULE_NAME = 'app-users-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      getValidationState,
    } = formValidation()

    const {
      resolveUserStatusName,
      roleOptions,
      statusOptions,
    } = useUsersList()

    return {
      resolveUserStatusName,
      avatarText,
      getValidationState,
      refFormUserUpdateObserver,
      roleOptions,
      statusOptions,
      userData,
      required,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
